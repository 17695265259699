<template>
  <Row class="page5">
    <Col style="width:48%;padding:0 1%;">
      <div class="description">
        <span class='title'><span class="title-text">{{picsTitle}}</span></span>
        <span class="angle1"></span>
        <span class="angle2"></span>
        <span class="angle3"></span>
        <span class="angle4"></span>
        <div class="describeList">
          <span v-if="describeList[chooseIndex]">{{describeList[chooseIndex]}}</span>
          <span v-else style="text-align:center;font-size: 18px;">暂无简介......</span>
        </div>
      </div>
      <div class="left">
        <china-map ref="chinaMap" :resdata="resdata"></china-map>
      </div>
    </Col>
    <Col style="width:51.5%;height: 95%;display: flex;flex-direction: column">
      <div class="center-bottom">
        <span class='title'><span class="title-text">{{ picsTitle }}</span></span>
        <span class="angle1"></span>
        <span class="angle2"></span>
        <span class="angle3"></span>
        <span class="angle4"></span>
<!--        <videoPlay></videoPlay>-->
<!--        <div class="chart-68" v-if="chooseIndex==0">-->
<!--          <area-chart ref="chart1" id="left_1" :config="cnfigData2"></area-chart>-->
<!--        </div>-->
        <div class="chart-68">
          <area-chart ref="chart1" id="left_1" :config="cnfigData1" :choiceIndex="6"></area-chart>
        </div>
        <div class="chart-32">
          <radar-chart ref="chart2" id="left_2" :data="chatRadarData"></radar-chart>
        </div>
      </div>
      <div class="bottom-list">
        <div class="bottom-left">
          <span class='title'><span class="title-text">{{ picsTitle }}</span></span>
          <span class="angle1"></span>
          <span class="angle2"></span>
          <span class="angle3"></span>
          <span class="angle4"></span>
          <workPics ref="workPic" :isBig="true"></workPics>
        </div>
        <div class="bottom-right" style="margin-left: 2%">
          <span class='title'><span class="title-text">荣誉图片</span></span>
          <span class="angle1"></span>
          <span class="angle2"></span>
          <span class="angle3"></span>
          <span class="angle4"></span>
          <honorPics ref="honor" :isLarge="true"></honorPics>
        </div>
      </div>
    </Col>
    <div class="tabNav">
      <div v-for="(item,index) in workName" :key="index" @click="chooseNav(item,index)" :class="index==chooseIndex?'on_workname':'workname'">{{item}}</div>
    </div>
  </Row>
</template>

<script>
const chinaMap  = () => import('./components/chinaMap');
const workPics = () => import('./components/page2/workPictures');
// const videoPlay =()=> import('./components/workVideo/video')
const areaChart = ()=> import('./components/areaChart');
const radarChart = () => import('./components/radar');
const honorPics = ()=> import('./components/page2/HonoraryPics.vue');

export default {
  name: 'workVideo',
  components: {
    chinaMap,
    workPics,//项目图片
    honorPics, //荣誉图片
    radarChart,
    areaChart,
    // videoPlay
  },
  data() {
    return {
      //tabNav子菜单
      workName:['儿童平安小课堂','校园减灾教室','紧急救灾',],
      chooseIndex:-1,
      picsTitle:'', //右下角图片标题
      describeList:[
          '搭建社会力量参与儿童安全教育与倡导安全保护的平台，提升儿童应对风险能力的儿童平安项目。通过关注校园安全的地方公益组织，协同学校，为教师提供培训、授课方法、课件、活动方案等技术支持，为学校应急管理提供方案设计及物资配套，以期通过有效且有趣的课程学习和活动参与，增强儿童的风险意识和自我防范技能，让儿童成为能够保护自己的“小超人”，并在此过程中进一步提升学校教师、社会组织项目专职的安全教育专业能力，提升项目学校的安全综合管理能力。',
          '通过在鹤壁市浚县7所、淇县11所项目学校，进行校园减灾教室建设，搭建鹤壁市安全教育教师交流互助平台。同时通过开展减灾课程开发、培训、督导，搭建具有“教学、展示、游戏、体验”四大功能的减灾的互动实体平台，从而提升校园综合减灾能力。',
          '紧急救灾计划以“提前备灾、联合行动、快速救援”为策略，建设属地化社会力量灾害应急机制。在重大灾害和中小型灾害中，通过与当地政府及社会力量的合作，面向弱势群体开展生命救援和生活救助；同时，努力提升社会救灾力量的专业化发展，推动社会组织参与灾害治理体系的改善。'
        ],
      //地图展示项目数据
      resdata:[
        {
          name: '长沙市',
          choiceIndex:1,
          value: 1,
          list:[
            {
              title: '儿童平安小课堂',
              soprtcount: '0',
              serviceCount: '10422',
              volunteerCount: '0',
              schoolCount: '24',
              money: '0',
              attent: '0',
            },
            {
              title: '校园减灾教室',
              soprtcount: '0',
              serviceCount: '0',
              volunteerCount: '0',
              schoolCount: '0',
              money: '0',
              attent: '0',
            },
            {
              title: '紧急救灾',
              soprtcount: '0',
              serviceCount: '80000',
              volunteerCount: '1200',
              schoolCount: '0',
              money: '134.6',
              attent: '11',
            },
          ]
        },
        {
          name:'株洲市',
          value: 1,
          choiceIndex:1,
          list:[
            {
              title: '儿童平安小课堂',
              soprtcount: '0',
              serviceCount: '6282',
              volunteerCount: '0',
              schoolCount: '8',
              money: '0',
              attent: '0',
            },
            {
              title: '校园减灾教室',
              soprtcount: '0',
              serviceCount: '0',
              volunteerCount: '0',
              schoolCount: '0',
              money: '0',
              attent: '0',
            },
            {
              title: '紧急救灾',
              soprtcount: '0',
              serviceCount: '6000',
              volunteerCount: '200',
              schoolCount: '0',
              money: '20.8',
              attent: '5',
            },
          ]
        },
        {
          name:'湘潭市',
          value: 1,
          choiceIndex:1,
          list:[
            {
              title: '儿童平安小课堂',
              soprtcount: '0',
              serviceCount: '0',
              volunteerCount: '0',
              schoolCount: '0',
              money: '0',
              attent: '0',
            },
            {
              title: '校园减灾教室',
              soprtcount: '0',
              serviceCount: '0',
              volunteerCount: '0',
              schoolCount: '0',
              money: '0',
              attent: '0',
            },
            {
              title: '紧急救灾',
              soprtcount: '0',
              serviceCount: '0',
              volunteerCount: '0',
              schoolCount: '0',
              money: '0',
              attent: '0',
            },
          ]
        },
        {
          name:'衡阳市',
          value: 1,
          choiceIndex:1,
          list:[
            {
              title: '儿童平安小课堂',
              soprtcount: '0',
              serviceCount: '0',
              volunteerCount: '0',
              schoolCount: '0',
              money: '0',
              attent: '0',
            },
            {
              title: '校园减灾教室',
              soprtcount: '0',
              serviceCount: '0',
              volunteerCount: '0',
              schoolCount: '0',
              money: '0',
              attent: '0',
            },
            {
              title: '紧急救灾',
              soprtcount: '0',
              serviceCount: '50000',
              volunteerCount: '500',
              schoolCount: '0',
              money: '86.78',
              attent: '5',
            },
          ]
        },
        {
          name:'常德市',
          value: 1,
          choiceIndex:1,
          list:[
            {
              title: '儿童平安小课堂',
              soprtcount: '0',
              serviceCount: '600',
              volunteerCount: '0',
              schoolCount: '1',
              money: '0',
              attent: '0',
            },
            {
              title: '校园减灾教室',
              soprtcount: '0',
              serviceCount: '0',
              volunteerCount: '0',
              schoolCount: '0',
              money: '0',
              attent: '0',
            },
            {
              title: '紧急救灾',
              soprtcount: '0',
              serviceCount: '2000',
              volunteerCount: '60',
              schoolCount: '0',
              money: '5',
              attent: '1',
            },
          ]
        },
        {
          name:'益阳市',
          value: 1,
          choiceIndex:1,
          list:[
            {
              title: '儿童平安小课堂',
              soprtcount: '0',
              serviceCount: '0',
              volunteerCount: '0',
              schoolCount: '0',
              money: '0',
              attent: '0',
            },
            {
              title: '校园减灾教室',
              soprtcount: '0',
              serviceCount: '0',
              volunteerCount: '0',
              schoolCount: '0',
              money: '0',
              attent: '0',
            },
            {
              title: '紧急救灾',
              soprtcount: '0',
              serviceCount: '45000',
              volunteerCount: '200',
              schoolCount: '0',
              money: '75.8',
              attent: '7',
            },
          ]
        },
        {
          name:'娄底市',
          value: 1,
          choiceIndex:1,
          list:[
            {
              title: '儿童平安小课堂',
              soprtcount: '0',
              serviceCount: '9228',
              volunteerCount: '0',
              schoolCount: '8',
              money: '0',
              attent: '0',
            },
            {
              title: '校园减灾教室',
              soprtcount: '0',
              serviceCount: '0',
              volunteerCount: '0',
              schoolCount: '0',
              money: '0',
              attent: '0',
            },
            {
              title: '紧急救灾',
              soprtcount: '0',
              serviceCount: '2000',
              volunteerCount: '90',
              schoolCount: '0',
              money: '5',
              attent: '2',
            },
          ]
        },
        {
          name:'郴州市',
          value: 1,
          choiceIndex:1,
          list:[
            {
              title: '儿童平安小课堂',
              soprtcount: '0',
              serviceCount: '1887',
              volunteerCount: '0',
              schoolCount: '7',
              money: '0',
              attent: '0',
            },
            {
              title: '校园减灾教室',
              soprtcount: '0',
              serviceCount: '0',
              volunteerCount: '0',
              schoolCount: '0',
              money: '0',
              attent: '0',
            },
            {
              title: '紧急救灾',
              soprtcount: '0',
              serviceCount: '0',
              volunteerCount: '0',
              schoolCount: '0',
              money: '0',
              attent: '0',
            },
          ]
        },
        {
          name:'永州市',
          value:1,
          choiceIndex:1,
          list:[
            {
              title: '儿童平安小课堂',
              soprtcount: '0',
              serviceCount: '0',
              volunteerCount: '0',
              schoolCount: '0',
              money: '0',
              attent: '0',
            },
            {
              title: '校园减灾教室',
              soprtcount: '0',
              serviceCount: '0',
              volunteerCount: '0',
              schoolCount: '0',
              money: '0',
              attent: '0',
            },
            {
              title: '紧急救灾',
              soprtcount: '0',
              serviceCount: '40000',
              volunteerCount: '200',
              schoolCount: '0',
              money: '42.8',
              attent: '4',
            },
          ]
        },
        {
          name:'怀化市',
          value: 1,
          choiceIndex:1,
          list:[
            {
              title: '儿童平安小课堂',
              soprtcount: '0',
              serviceCount: '3483',
              volunteerCount: '0',
              schoolCount: '8',
              money: '0',
              attent: '0',
            },
            {
              title: '校园减灾教室',
              soprtcount: '0',
              serviceCount: '0',
              volunteerCount: '0',
              schoolCount: '0',
              money: '0',
              attent: '0',
            },
            {
              title: '紧急救灾',
              soprtcount: '0',
              serviceCount: '15000',
              volunteerCount: '400',
              schoolCount: '0',
              money: '95.4',
              attent: '9',
            },
          ]
        },
        {
          name:'湘西土家族苗族自治州',
          value: 1,
          choiceIndex:1,
          list:[
            {
              title: '儿童平安小课堂',
              soprtcount: '0',
              serviceCount: '1910',
              volunteerCount: '0',
              schoolCount: '4',
              money: '0',
              attent: '0',
            },
            {
              title: '校园减灾教室',
              soprtcount: '0',
              serviceCount: '0',
              volunteerCount: '0',
              schoolCount: '0',
              money: '0',
              attent: '0',
            },
            {
              title: '紧急救灾',
              soprtcount: '0',
              serviceCount: '30000',
              volunteerCount: '200',
              schoolCount: '0',
              money: '46.5',
              attent: '2',
            },
          ]
        },
        {
          name:'张家界市',
          value: 1,
          choiceIndex:1,
          list:[
            {
              title: '儿童平安小课堂',
              soprtcount: '0',
              serviceCount: '0',
              volunteerCount: '0',
              schoolCount: '0',
              money: '0',
              attent: '0',
            },
            {
              title: '校园减灾教室',
              soprtcount: '0',
              serviceCount: '0',
              volunteerCount: '0',
              schoolCount: '0',
              money: '0',
              attent: '0',
            },
            {
              title: '紧急救灾',
              soprtcount: '0',
              serviceCount: '0',
              volunteerCount: '0',
              schoolCount: '0',
              money: '0',
              attent: '0',
            },
          ]
        },
        {
          name:'岳阳市',
          value: 1,
          choiceIndex:1,
          list:[
            {
              title: '儿童平安小课堂',
              soprtcount: '0',
              serviceCount: '3996',
              volunteerCount: '0',
              schoolCount: '8',
              money: '0',
              attent: '0',
            },
            {
              title: '校园减灾教室',
              soprtcount: '0',
              serviceCount: '0',
              volunteerCount: '0',
              schoolCount: '0',
              money: '0',
              attent: '0',
            },
            {
              title: '紧急救灾',
              soprtcount: '0',
              serviceCount: '75000',
              volunteerCount: '2500',
              schoolCount: '0',
              money: '115',
              attent: '15',
            },
          ]
        },
        {
          name:'邵阳市',
          value: 1,
          choiceIndex:1,
          list:[
            {
              title: '儿童平安小课堂',
              soprtcount: '0',
              serviceCount: '868',
              volunteerCount: '0',
              schoolCount: '4',
              money: '0',
              attent: '0',
            },
            {
              title: '校园减灾教室',
              soprtcount: '0',
              serviceCount: '0',
              volunteerCount: '0',
              schoolCount: '0',
              money: '0',
              attent: '0',
            },
            {
              title: '紧急救灾',
              soprtcount: '0',
              serviceCount: '20000',
              volunteerCount: '500',
              schoolCount: '0',
              money: '21.4',
              attent: '8',
            },
          ]
        },
      ],
      //项目图片数组
      workPicsList: [
        {
          name: '儿童平安',
          picsList: [
            require('@/assets/disasterManagement/childPeace/1.jpg'),
            require('@/assets/disasterManagement/childPeace/2.jpg'),
            require('@/assets/disasterManagement/childPeace/3.jpg'),
          ]
        },
        {
          name: '河南洪灾救援',
          picsList: [
            require('@/assets/disasterManagement/Henan_FloodRescue/1.jpg'),
            require('@/assets/disasterManagement/Henan_FloodRescue/2.jpg'),
            require('@/assets/disasterManagement/Henan_FloodRescue/3.jpg'),
            require('@/assets/disasterManagement/Henan_FloodRescue/4.jpg'),
            require('@/assets/disasterManagement/Henan_FloodRescue/5.jpg'),
            require('@/assets/disasterManagement/Henan_FloodRescue/6.jpg'),
            require('@/assets/disasterManagement/Henan_FloodRescue/7.jpg'),
            require('@/assets/disasterManagement/Henan_FloodRescue/8.jpg'),
            require('@/assets/disasterManagement/Henan_FloodRescue/9.jpg'),
            require('@/assets/disasterManagement/Henan_FloodRescue/10.jpg'),
          ]
        },
        {
          name: '紧急救灾',
          picsList: []
        },
      ],
      cnfigData1: {
        color: '#5CB1C1',
        name: ['（人）', '（个）'],
        data: [
                  {
                    name: '志愿者人数',
                    color: ['#9e70ff', '#6e5eff'],
                    data: [],
                  },
                  {
                    name: '社会组织参与数',
                    color: ['#48cefd', '#5356f1'],
                    data: [],
                  }
        ]
      },
      chatRadarData: {
        title: '各城市数据对比',
        position: ['center', '85%'],
        center: ['50%', '50%'],
        indicator: [
          {text: '长沙市'},
          {text: '株洲市'},
          {text: '湘潭市'},
          {text: '衡阳市'},
          {text: '常德市'},
          {text: '益阳市'},
          {text: '娄底市'},
          {text: '郴州市'},
          {text: '永州市'},
          {text: '怀化市'},
          {text: '湘西'},
          {text: '张家界'},
          {text: '岳阳市'},
          {text: '邵阳市'},
        ],
        data: [
          {
            name: '服务人次',
            color: '#0DF5F8',
            value: [],
          },
          {
            name: '投入款物（万元）',
            color: '#7921AD',
            value: [],
          }
        ]
      },
    }
  },
  mounted() {
    setTimeout(()=>{
      this.chooseNav(this.workName[0],0);
    },100)
  },
  methods:{
    //  点击子菜单选择
    chooseNav(item,index){
      this.chooseIndex = index;
      //动态获取图片数据
      this.$refs.workPic.workPicsList2 = this.workPicsList[index].picsList;
      this.$refs.workPic.isChoose = !this.$refs.workPic.isChoose; //点击菜单，右下角图片重新渲染滑动
      this.$refs.workPic.loading=false; //加载中
      // 荣誉图片
      // this.$refs.honor.honoraryPicsList = this.workPicsList[index].picsList;
      this.$refs.honor.isChoice = !this.$refs.honor.isChoice; //点击菜单，右下角荣誉图片重新渲染滑动
      this.$refs.honor.load=false; //加载中
      //选择导航栏 把index对应传给value 从而获取不同数据
      for (var i=0;i<this.resdata.length;i++){
        this.resdata[i].choiceIndex=index;

        //动态计算每个城市的每个项目总和  (目的：若项目数据都为0，湖南地图显示不同颜色)
        let totalValue = Number(this.resdata[i].list[index].attent) + Number(this.resdata[i].list[index].money)
            + Number(this.resdata[i].list[index].schoolCount) + Number(this.resdata[i].list[index].serviceCount)
            + Number(this.resdata[i].list[index].soprtcount) + Number(this.resdata[i].list[index].volunteerCount)
        this.resdata[i].value = totalValue;
      }

      this.$refs.chinaMap.seriesData = this.resdata
      this.$refs.chinaMap.setMap(); //刷新地图数据
      this.picsTitle = item; //标题随着nav导航选项而变化
      //每次点击导航选项将之前数据置空 再进行下面操作
      this.$refs.chart1.config.data[0].data = [];
      this.$refs.chart1.config.data[1].data = [];
      this.$refs.chart2.data.data[0].value=[];
      this.$refs.chart2.data.data[1].value=[];

      //各城市的数据遍历获取
      this.resdata.forEach(it=>{
        // this.$refs.chart2.data.data[0].value.push(it.list[index].soprtcount) //活动场次
        // this.$refs.chart1.config.data[1].data.push(it.list[index].attent) //社会组织参与数
        // this.$refs.chart1.config.data[0].data.push(it.list[index].volunteerCount) //志愿者人数
        // this.$refs.chart1.config.data[1].data.push(it.list[index].schoolCount) //覆盖学校数量
        this.$refs.chart2.data.data[1].value.push(it.list[index].money) //右边投入款物
        this.$refs.chart2.data.data[0].value.push(it.list[index].serviceCount) //右边服务人次
      })
      // 儿童平安小课堂的志愿者人数和社会组织参与数改成覆盖学校数量
      if(index==0){
        this.resdata.forEach(it=>{
          this.$refs.chart1.config.data[0].name = '服务人次';
          this.$refs.chart1.config.data[0].data.push(it.list[index].serviceCount) //右边服务人次
          this.$refs.chart1.config.data[1].name = '覆盖学校数量';
          this.$refs.chart1.config.data[1].data.push(it.list[index].schoolCount) //覆盖学校数量
        })
      }else{
        this.$refs.chart1.choiceIndex = index;
        this.resdata.forEach(it=>{
          this.$refs.chart1.config.data[1].name = '社会组织参与数';
          this.$refs.chart1.config.data[0].name = '志愿者人数'
          this.$refs.chart1.config.data[1].data.push(it.list[index].attent) //社会组织参与数
          this.$refs.chart1.config.data[0].data.push(it.list[index].volunteerCount) //志愿者人数
        })
      }
      //刷新子组件数据
      this.$refs.chart1.setChart();
      this.$refs.chart2.setChart();
    },
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.resizeFn)
  }
}
</script>

<style lang="less" scoped>
.page5 {
  height: 98%;
  width: 100%;
  padding: 8px 20px 18px;
  background: #03044A;
  overflow: hidden;
  position: relative;
  .tabNav{
    width: auto;
    margin: 0 auto;
    height: 4.5%;
    line-height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 5px;
    margin-bottom: 1%;
    position: absolute;
    bottom: -2.5%;
    left: 45%;
    .workname{
      color:#fff;
      font-size: 15px;
      width: auto;
      height: 100%;
      line-height: 38px;
      min-width: 90px;
      padding: 0 10px;
      text-align: center;
      background-color: #4b92e1;
      border-right:1px solid #fff;
      font-family: "华文细黑", Courier New, Courier, monospace;
    }
    .on_workname{
      color:#4b92e1;
      font-size: 15px;
      width: auto;
      height: 100%;
      line-height: 35px;
      min-width: 90px;
      padding: 0 10px;
      text-align: center;
      border-radius: 3px;
      background-color: #fff;
      font-family: "宋体", "Times New Roman", Times, serif;
    }
  }
  .ivu-col {
    height: 100%;
    float: left;
  }

  .left, .center-bottom,.description,.bottom-left,.bottom-right{
    height: 95%;
    border: 1px solid #0D2451;
    position: relative;
    background: #151456;

    .left1 {
      border-bottom: 1px solid #0D2451;
      background: #151456;
    }

    .title {
      position: absolute;
      display: inline-block;
      color: #6EDDF1;
      border: 2px solid #6EDDF1;
      height: 18px;
      padding: 0 2px;
      left: 50%;
      transform: translate(-50%, -50%);

      .title-text {
        position: relative;
        font-size: 16px;
        background: #09102E;
        display: inline-block;
        padding: 0 4px;
        white-space: nowrap;
        transform: translateY(-5px);
        font-family: "宋体", "Times New Roman", Times, serif;
      }
    }
  }
  .bottom-left,.bottom-right{
    width: 50%;
    height: 100%!important;
  }
  .left{
    height: 76%!important;
    background: none!important;
    border: none!important;
  }
  .description{
    background: none!important;
    height: 22%!important;
    box-sizing: border-box;
    padding-bottom: 2%;
    margin-bottom: -1.5%;
    //width: 105%!important;;
  }
  .describeList{
    text-indent: 2%;
    display: flex;
    height: 96%;
    width: 98%;
    margin: 2% auto;
    align-items: center;
    color:rgb(167,198,235);
    line-height: 27px;
    font-size: 15px;
    //overflow-y: scroll;
    font-family: "华文细黑", Courier New, Courier, monospace;
    span{
      width: 100%;
      height: auto;
      max-height: 100%;
    }
  }
  .bottom-list{
    width: 100%;
    height: 50%!important;
    display: flex;
    box-sizing: border-box;
  }

  .center-bottom {
    height: 48%;
    margin-bottom: 2%;

    .bottom-radars {
      margin-top: 5px;
      height: 55%;
    }

    .bottom-bars {
      height: 45%;
    }
    .chart-68 {
      width: 68%;
      height: 100%;
      float: left;
    }

    .chart-32 {
      width: 32%;
      height: 100%;
      float: left;
    }
  }

}
</style>
